import { qsa, qs, addGlobalEventListener } from "../utils/dom";
import sendRequest from "../utils/sendRequest";

let controller = null;

export async function sendForm(form) {
  try {

    if (controller) {
        controller.abort()
    }
    
    controller = new AbortController()
    const signal = controller.signal

    let data = new FormData(form);
    let dataset = form.dataset

    if (dataset) {
      Object.keys(dataset).forEach((key) => {
        data.append( key, dataset[key] );
      });
    }

    form.classList.add( 'loading' );

    const response = await sendRequest({method: 'POST', data: data, signal})
    
    if ( response ) {
        const results = await response;
        const data = await response?.data;
    
        form.classList.remove( 'loading' );

        if ( results.success ) {
          if ( data?.redirect ) {
            window.location.href = data?.redirect;
          }

          if ( data?.message ) {
            form.insertAdjacentHTML( 'afterbegin', `<div class="sk-main-success-form js-success-form">${data?.message}</div>` );

            setTimeout(() => qs('.js-success-form').remove(), 2000);
          }
        } else {
          data.forEach((error) => {

            if ( error.code === 'invalid_credentials' ) {
              form.insertAdjacentHTML( 'beforeend', `<div class="sk-main-error-form js-error-form">${error.message}</div>` );
            }

            const field = qs(`#${error.code}`);

            if ( field ) field.classList.add( 'is-invalid' );

          });
        }
    }
    
} catch(err) {
    console.error(err)
}
} 

export const initFormEvents = () => {

  addGlobalEventListener( 'input', '.js-form-field', ({target}) => {
    if ( target.classList.contains('is-invalid') ) target.classList.remove('is-invalid');
  } );

  addGlobalEventListener( 'submit', '.js-form', ({target}) => {
    const errorsInputs = qsa( '.is-invalid', target );
    const errors = qsa( '.js-error-form', target );
    errors.forEach((error) => error.remove());
    errorsInputs.forEach((input) => input.classList.remove('is-invalid'));
  } );

  addGlobalEventListener('submit', '.js-form', (e) => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.target;

    form.classList.add('was-validated');

    if ( !form.checkValidity() ) {
        return;
    }

    sendForm(form);
  })

}