import { addGlobalEventListener } from "./utils/dom";
import initTooltips from "./modules/tooltip";
import { initFormEvents } from "./modules/forms.js";

window.addEventListener('load', () => {
  initTooltips();
  initFormEvents();
  
  addGlobalEventListener('click', '.js-print-button', () => {
    window.print()
  })
});

window.addEventListener('resize', () => {
});
