export default async (args) => {
  const { method, data, nonce, signal } = args;

  try {
    const reqBody = {
      method,
      credentials: 'same-origin',
      headers: {
        Accept: "application/json",
        "X-WP-Nonce": nonce || themeVars?.nonce,
      },
      body: data,
      signal: signal,
    }

    const res = await fetch(themeVars.ajax, reqBody)

    return res.json()
  } catch(err) {
    console.log('Request failed', err.message)
  }
}